import React from 'react';
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

const TeamsProfilePage = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-between">
      <Header />
      {/* Contenido principal */}
      <div className="flex-grow flex flex-col items-center py-8 px-4">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">
          Conoce a Nuestros Creadores
        </h1>

        {/* Contenedor para los perfiles de las dos personas */}
        <div className="flex flex-col md:flex-row md:space-x-8 items-stretch w-full md:w-3/4">
          {/* Perfil Persona 1 */}
          <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-1/2 mb-8 md:mb-0 flex flex-col h-full">
            <h2 className="text-2xl font-bold text-gray-900 text-center">
              Diego Alonso Diestra Vega
            </h2>
            <p className="mt-2 text-gray-600 text-center">
              Desarrollador Full Stack & Fundador
            </p>

            <div className="mt-4 flex flex-col items-center flex-grow">
              <img
                src="https://via.placeholder.com/150"
                alt="Persona 1"
                className="rounded-full shadow-md w-32 h-32 object-cover"
              />
              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Descripción:</h3>
                <p className="text-gray-600">
                  Diego es un desarrollador full stack con una sólida experiencia
                  en el desarrollo de soluciones tecnológicas. Ha liderado el
                  desarrollo completo de TuEspacio, desde el diseño del front-end
                  hasta la implementación del back-end y la arquitectura del
                  sistema. Su compromiso con el bienestar mental lo ha impulsado
                  a crear una página web accesible y eficiente para las personas
                  que lo necesitan.
                </p>
              </div>

              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Contribuciones:</h3>
                <p className="text-gray-600">
                  Diego lideró el diseño de la interfaz de usuario, la
                  implementación del servidor, la gestión de la base de datos y
                  la integración del chatbot. Su enfoque en la optimización de
                  cada componente garantizó un sistema sólido, eficiente y capaz
                  de ofrecer una experiencia fluida a los usuarios.
                </p>
              </div>

              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Motivación:</h3>
                <p className="text-gray-600">
                  Mi objetivo es aprovechar la tecnología para hacer que el apoyo
                  psicológico sea accesible para todos. Aspiro a crear una
                  plataforma que no solo sea funcional y fácil de usar, sino que
                  también brinde una experiencia de apoyo cercana y efectiva para
                  quienes buscan mejorar su bienestar mental.
                </p>
              </div>
            </div>
          </div>

          {/* Perfil Persona 2 */}
          <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-1/2 mb-8 md:mb-0 flex flex-col h-full">
            <h2 className="text-2xl font-bold text-gray-900 text-center">
              Carlos Alexis Simón Quispe
            </h2>
            <p className="mt-2 text-gray-600 text-center">
              Coordinador de Proyecto & Co-fundador
            </p>

            <div className="mt-4 flex flex-col items-center flex-grow">
              <img
                src="https://via.placeholder.com/150"
                alt="Persona 2"
                className="rounded-full shadow-md w-32 h-32 object-cover"
              />
              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Descripción:</h3>
                <p className="text-gray-600">
                  Carlos es ingeniero de sistemas con amplia experiencia en la
                  gestión de proyectos tecnológicos. Como Coordinador de Proyecto
                  en TuEspacio, se encarga de organizar las reuniones del equipo,
                  realizar evaluaciones de las necesidades de los usuarios y
                  supervisar los informes del proyecto. Su enfoque está en
                  garantizar que la plataforma cumpla con los más altos
                  estándares de accesibilidad y funcionalidad.
                </p>
              </div>

              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Contribuciones:</h3>
                <p className="text-gray-600">
                  Carlos ha liderado las reuniones estratégicas del equipo,
                  evaluado las necesidades de la población objetivo y documentado
                  cada fase del desarrollo, asegurando que el proyecto se mantenga
                  alineado con los objetivos de accesibilidad y calidad.
                </p>
              </div>

              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Motivación:</h3>
                <p className="text-gray-600">
                  Creo firmemente que todos merecen acceso a un tratamiento
                  psicológico de calidad, independientemente de las barreras
                  geográficas o económicas. Mi objetivo es utilizar la tecnología
                  para crear una plataforma que acerque ese apoyo a quienes más lo
                  necesitan, de manera accesible, segura y eficaz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default TeamsProfilePage;
