import React, { useEffect, useState } from "react";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaUserPlus } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { BiLogOut, BiHome } from "react-icons/bi";
import Avatar from "./Avatar";
import { useDispatch, useSelector } from "react-redux";
import EditUserDetails from "./EditUserDetails";
import SearchUser from "./SearchUser";
import { logout } from "../redux/userSlice";

const Sidebar = ({ onChatClick }) => {
  const user = useSelector((state) => state?.user);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [openSearchUser, setOpenSearchUser] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const socketConnection = useSelector((state) => state?.user?.socketConnection);
  const dispatch = useDispatch();

  useEffect(() => {
    if (socketConnection && user._id) {
      socketConnection.emit("sidebar", user._id);

      socketConnection.on("conversation", (data) => {
        const conversationUserData = data.map((conversationUser) => {
          if (conversationUser?.sender?._id === conversationUser?.receiver?._id) {
            return {
              ...conversationUser,
              userDetails: conversationUser?.sender,
            };
          } else if (conversationUser?.receiver?._id !== user?._id) {
            return {
              ...conversationUser,
              userDetails: conversationUser.receiver,
            };
          } else {
            return {
              ...conversationUser,
              userDetails: conversationUser.sender,
            };
          }
        });

        setAllUser(conversationUserData);
      });
    }
  }, [socketConnection, user]);

  const handleLogout = () => {
    fetch('http://localhost:8080/api/logout', { 
      method: 'GET', 
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          dispatch(logout());
          localStorage.clear();
        } else {
          console.error('Error al cerrar sesión en el servidor');
        }
      })
      .catch((error) => console.error('Error en el logout:', error));
  };

  return (
    <>
      {/* Desktop View - Sidebar on the left */}
      <div className="hidden lg:flex fixed left-0 top-0 h-full w-24 bg-white shadow-lg flex-col items-center py-4 z-50">
        <NavLink to="/app/home" className="mb-8" title="Home">
          <BiHome size={24} className="hover:text-green-500" />
        </NavLink>
        <div
          title="Chat"
          onClick={onChatClick}
          className="mb-8 cursor-pointer"
        >
          <IoChatbubbleEllipses size={24} className="hover:text-green-500" />
        </div>
        <div
          title="Add Friend"
          onClick={() => setOpenSearchUser(true)}
          className="mb-8 cursor-pointer"
        >
          <FaUserPlus size={24} className="hover:text-green-500" />
        </div>
        <button
          className="mx-auto"
          title={user?.name}
          onClick={() => setEditUserOpen(true)}
        >
          <Avatar
            width={40}
            height={40}
            name={user?.name}
            imageUrl={user?.profile_pic}
            userId={user?._id}
          />
        </button>
        <button
          title="Logout"
          className="mt-auto cursor-pointer hover:bg-slate-200 rounded-full p-2"
          onClick={handleLogout}
        >
          <BiLogOut size={30} className="hover:text-red-500" />
        </button>
      </div>

      {/* Mobile View - Bottom bar */}
      <div className="lg:hidden fixed bottom-0 w-full bg-white shadow-lg flex justify-around items-center py-2 z-50">
        <NavLink to="/app/home" title="Home">
          <BiHome size={24} className="hover:text-green-500" />
        </NavLink>
        <div title="Chat" onClick={onChatClick} className="cursor-pointer">
          <IoChatbubbleEllipses size={24} className="hover:text-green-500" />
        </div>
        <div title="Add Friend" onClick={() => setOpenSearchUser(true)} className="cursor-pointer">
          <FaUserPlus size={24} className="hover:text-green-500" />
        </div>
        <button onClick={() => setEditUserOpen(true)} title={user?.name}>
          <Avatar
            width={40}
            height={40}
            name={user?.name}
            imageUrl={user?.profile_pic}
            userId={user?._id}
          />
        </button>
        <button onClick={handleLogout} title="Logout" className="cursor-pointer">
          <BiLogOut size={30} className="hover:text-red-500" />
        </button>
      </div>

      {editUserOpen && (
        <EditUserDetails onClose={() => setEditUserOpen(false)} user={user} />
      )}
      {openSearchUser && (
        <SearchUser onClose={() => setOpenSearchUser(false)} />
      )}
    </>
  );
};

export default Sidebar;
