import React from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de importar Link

function Footer() {
  return (
    <footer className="bg-white py-8 border-t">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Left Section - Logo and Description */}
          <div className="md:w-1/4 text-center md:text-left">
            {/* Logo */}
            <a href="#" className="text-xl font-bold text-blue-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10l1.79 1.79a1.004 1.004 0 001.42 0L12 6.83l5.79 5.96a1.004 1.004 0 001.42 0L21 10M3 20h18M12 20v-6" />
              </svg>
            </a>
            <p className="mt-4 text-gray-500">
              Making the world a better place through constructing elegant hierarchies.
            </p>
            {/* Social Icons */}
            <div className="flex justify-center md:justify-start space-x-4 mt-4">
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>

          {/* Right Section - Links */}
          <div className="flex flex-col md:flex-row mt-6 md:mt-0 space-y-6 md:space-y-0 md:space-x-12">
            <div>
              <h5 className="text-gray-900 font-bold">Atención psicológica</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="/infoansiedad" className="hover:text-gray-900">Ansiedad</Link></li>
                <li><Link to="/infoestres" className="hover:text-gray-900">Estrés</Link></li>
                <li><Link to="/pagescitas" className="hover:text-gray-900">Citas</Link></li>
              </ul>
            </div>

            <div>
              <h5 className="text-gray-900 font-bold">Recursos</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="#" className="hover:text-gray-900">Artículos sobre ansiedad</Link></li>
                <li><Link to="/gestionarestres" className="hover:text-gray-900">Cómo gestionar el estrés</Link></li>
              </ul>
            </div>

            <div>
              <h5 className="text-gray-900 font-bold">Apoyo</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="#" className="hover:text-gray-900">Apoyo con Practicantes</Link></li>
                <li><Link to="#" className="hover:text-gray-900">Apoyo con Profesionales</Link></li>
                <li><Link to="#" className="hover:text-gray-900">Chatbot</Link></li>
                <li><Link to="#" className="hover:text-gray-900">¿Por qué nosotros?</Link></li>
              </ul>
            </div>

            <div>
              <h5 className="text-gray-900 font-bold">Compañía</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="/nosotros" className="hover:text-gray-900">Nosotros</Link></li>
                <li><Link to="/#trabaja-con-nosotros" className="hover:text-gray-900">Trabaja con nosotros</Link></li>
                <li><Link to="/creadores" className="hover:text-gray-900">Creadores</Link></li>
              </ul>
            </div>

            <div>
              <h5 className="text-gray-900 font-bold">Legal</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="/privacidad" className="hover:text-gray-900">Política de privacidad</Link></li>
                <li><Link to="/terminos" className="hover:text-gray-900">Términos y Condiciones</Link></li>
              </ul>
            </div>

            <div>
              <h5 className="text-gray-900 font-bold">Contacto</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><a href="mailto:support@tuespacio.com" className="hover:text-gray-900">Correo electrónico</a></li>
                <li><a href="mailto:support@tuespacio.com" className="hover:text-gray-900">Contacto por Whatsapp</a></li>
              </ul>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 border-t pt-6 text-center">
          <p className="text-gray-500">
            © 2024 TuEspacio, Inc. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
