import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Confetti from "react-confetti";
import MessagesModal from "../components/MessagesModal";
import { setOnlineUser, setSocketConnection, setUser, logout } from "../redux/userSlice";
import io from "socket.io-client";
import UserSearchCard from "../components/UserSearchCard";
import toast from "react-hot-toast";
import logo from "../assets/logotexto.png";

const Home = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchUser, setSearchUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [messagesModalOpen, setMessagesModalOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  const fetchUserDetails = async () => {
    try {
      const URL = `${process.env.REACT_APP_BACKEND_URL}/api/user-details`;
      const token = localStorage.getItem("token");

      const response = await axios({
        url: URL,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      if (response.data.data.logout) {
        dispatch(logout());
        navigate("/app/email");
      } else {
        dispatch(setUser(response.data.data));
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleSearchUser = async () => {
    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/search-user`;
    try {
      setLoading(true);
      const response = await axios.post(URL, { search });
      setLoading(false);

      const filteredUsers = response.data.data.filter(
        (user) =>
          user.name.startsWith("Practicante") ||
          user.name.startsWith("Psicólogo")
      );

      setSearchUser(filteredUsers);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    handleSearchUser();
  }, [search]);

  useEffect(() => {
    const socketConnection = io(process.env.REACT_APP_BACKEND_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
    });

    socketConnection.on("onlineUser", (data) => {
      dispatch(setOnlineUser(data));
    });

    dispatch(setSocketConnection(socketConnection));

    return () => {
      socketConnection.disconnect();
    };
  }, [dispatch]);

  const basePath = location.pathname === "/app/home";

  return (
    <div className="flex lg:flex-row min-h-screen overflow-hidden">
      {showConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      {/* Sidebar */}
      <Sidebar onChatClick={() => setMessagesModalOpen(true)} />

      {/* Main Content */}
      <div className="flex-1 p-4 pt-16 lg:pt-4 lg:ml-24 overflow-y-auto"> {/* Añadido lg:ml-24 para respetar el sidebar */}
        {basePath ? (
          <div className="text-center pb-12">
            <img
              src={logo}
              loading="lazy"
              alt="logo"
              className="mx-auto w-48"
            />
            <h1 className="text-2xl mt-4">
              Selecciona un psicólogo o practicante
            </h1>
            <div className="mt-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
              <h3 className="text-xl font-semibold">
                ¡Novedades próximamente!
              </h3>
              <p>
                Pronto se podrá realizar videollamadas, estamos trabajando en
                ello.
              </p>
            </div>
            <div className="mt-6 p-4 bg-white border rounded-lg shadow w-full max-w-7xl mx-auto">
              <h3 className="text-xl font-semibold mb-4">Psicólogos y practicantes</h3>
              {loading ? (
                <p>Cargando...</p>
              ) : (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  {searchUser.map((user) => (
                    <UserSearchCard key={user._id} user={user} />
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Outlet />
        )}
      </div>
      <MessagesModal
        isOpen={messagesModalOpen}
        onClose={() => setMessagesModalOpen(false)}
      />
    </div>
  );
};

export default Home;
