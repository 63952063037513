import React, { useEffect } from 'react';  // Asegúrate de importar useEffect
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

const Terms = () => {
  useEffect(() => {
    // Forzar el desplazamiento al inicio cuando se carga el componente
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-grow bg-gray-50 flex flex-col items-center p-6 md:p-12">
        <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-8 md:p-16">
          <h1 className="text-5xl font-bold text-gray-900 mb-4 text-center">
            Términos y Condiciones de Uso de Tu Espacio
          </h1>
        <p className="text-gray-600 text-lg text-justify mb-8">
        Bienvenido a <span className="font-semibold">TuEspacio</span>, una plataforma diseñada para ofrecer servicios de apoyo psicológico, test de ansiedad, acceso a profesionales, y más. Al acceder o utilizar cualquiera de los servicios ofrecidos en Tu Espacio, aceptas cumplir con los siguientes Términos y Condiciones, así como con nuestra Política de Privacidad.</p>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">1. Aceptación de los Términos</h2>
          <p className="text-gray-600 text-justify mb-8">
          Al acceder o usar los servicios de <span className="font-semibold">TuEspacio</span>, aceptas los presentes Términos y Condiciones. Si no estás de acuerdo con ellos, debes abstenerte de utilizar LA PLATAFORMA. Tu Espacio se reserva el derecho de modificar estos Términos en cualquier momento y sin previo aviso. Cualquier modificación se publicará en el sitio web y será efectiva de inmediato.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">2. Descripción de los Servicios</h2>
          <ul className="list-disc list-inside text-gray-600 ">
            <li className="mb-2">Realizar test para evaluar la ansiedad y otros trastornos relacionados.</li>
            <li className="mb-2">Conectar a los usuarios con profesionales y practicantes en salud mental.</li>
            <li className="mb-2">Proporcionar información sobre tratamiento y bienestar mental.</li>
            <li className="mb-2">Utilizar un chatbot para asistencia 24/7.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">3. Registro y Acceso a los Servicios</h2>
          <p className="text-gray-600 text-justify mb-4">
          Para acceder a ciertos servicios de Tu Espacio, es posible que debas crear una cuenta proporcionándonos información veraz y actualizada. Al registrarte, te comprometes a:
          </p>
          <ul className="list-disc list-inside text-gray-600 mt-2">
          <li className="mb-2">Proporcionar información precisa y completa.</li>
          <li className="mb-2">Mantener la confidencialidad de tus credenciales de acceso.</li>
          <li className="mb-2">Notificar cualquier uso no autorizado de tu cuenta.</li>
          </ul>
          <p className="text-gray-600 text-justify mb-4">
          Nos reservamos el derecho de suspender o cancelar tu cuenta si detectamos cualquier uso fraudulento, abusivo o contrario a los presentes Términos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">4. Uso de la Plataforma</h2>
          <p className="text-gray-600 text-justify mb-4">
          El uso de LA PLATAFORMA debe ser conforme a la ley y a estos Términos y Condiciones. No puedes utilizar LA PLATAFORMA para:
          </p>
          <ul className="list-disc list-inside text-gray-600 mt-2">
          <li className="mb-2">Publicar o compartir contenido ofensivo, discriminatorio o inapropiado.</li>
          <li className="mb-2">Vulnerar los derechos de propiedad intelectual de Tu Espacio o de terceros.</li>
          <li className="mb-2">Causar daños a la plataforma o intentar acceder de forma no autorizada a los sistemas de Tu Espacio.</li>
          </ul>
          <p className="text-gray-600 text-justify mb-4">
          El incumplimiento de estas disposiciones podrá dar lugar a la suspensión o cancelación de tu cuenta.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">5. Servicios Prestados por Terceros</h2>
          <p className="text-gray-600 text-justify">
          Tu Espacio colabora con terceros, incluyendo practicantes y profesionales de la salud mental, para la prestación de ciertos servicios. Tu Espacio no se responsabiliza por las acciones, omisiones o calidad de los servicios prestados por terceros. Si tienes un problema con los servicios de un profesional o practicante, deberás resolverlo directamente con ellos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">6. Proceso de Evaluación y Resultados</h2>
          <p className="text-gray-600 text-justify">
          Los resultados de los test de ansiedad u otras pruebas ofrecidas en LA PLATAFORMA son orientativos y no constituyen un diagnóstico clínico. Te recomendamos que busques la opinión de un profesional en salud mental para recibir un diagnóstico preciso y un tratamiento adecuado.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">7. Tarifas y Pagos</h2>
          <p className="text-gray-600 text-justify">
          El acceso a algunos servicios de Tu Espacio puede estar sujeto a tarifas o pagos, que se informarán claramente antes de la contratación del servicio. Al aceptar los servicios de pago, aceptas proporcionar información de pago válida y autorizar a Tu Espacio o a sus proveedores de servicios a realizar los cargos correspondientes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">8. Propiedad Intelectual</h2>
          <p className="text-gray-600 text-justify">
          Todo el contenido disponible en Tu Espacio, incluyendo textos, gráficos, logotipos, y software, es propiedad de Tu Espacio o de sus proveedores y está protegido por las leyes de propiedad intelectual. No puedes reproducir, distribuir, o utilizar este contenido sin la autorización expresa de Tu Espacio.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">9. Privacidad</h2>
          <p className="text-gray-600 text-justify">
          El tratamiento de tus datos personales se rige por nuestra Política de Privacidad, la cual aceptas al utilizar nuestros servicios. Tu Espacio se compromete a proteger tu información personal y a utilizarla únicamente para los fines establecidos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">10. Limitación de Responsabilidad</h2>
          <p className="text-gray-600 text-justify">
          Tu Espacio no garantiza que los servicios ofrecidos sean ininterrumpidos o libres de errores. En la medida permitida por la ley, Tu Espacio no será responsable por daños directos, indirectos, incidentales, especiales o consecuentes que resulten del uso o la imposibilidad de utilizar los servicios de LA PLATAFORMA.
          </p>
          <p className="text-gray-600 text-justify mt-1">
          Esto incluye, sin limitación, los daños relacionados con la pérdida de datos, daños al equipo o fallas técnicas. El uso de Tu Espacio es bajo tu propio riesgo.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">11. Modificaciones a los Servicios</h2>
          <p className="text-gray-600 text-justify">
          Tu Espacio se reserva el derecho de modificar, suspender o descontinuar temporal o permanentemente cualquier servicio o funcionalidad de la plataforma en cualquier momento y sin previo aviso.
          </p>
        </section>

       <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">12. Enlaces a Terceros</h2>
          <p className="text-gray-600 text-justify">
          LA PLATAFORMA puede contener enlaces a sitios web de terceros. Tu Espacio no es responsable del contenido, políticas o prácticas de estos sitios externos. El acceso a dichos sitios es bajo tu propio riesgo.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">13. Terminación</h2>
          <p className="text-gray-600 text-justify">
          Tu Espacio se reserva el derecho de suspender o cancelar tu acceso a los servicios en cualquier momento, con o sin causa, sin previo aviso. Si deseas cancelar tu cuenta, puedes hacerlo contactando con nuestro equipo de soporte.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">14. Ley Aplicable y Jurisdicción</h2>
          <p className="text-gray-600 text-justify">
          Estos Términos se rigen por las leyes peruanas. Cualquier controversia derivada del uso de Tu Espacio será sometida a la jurisdicción de los tribunales competentes en Lima, Perú.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">15. Contacto</h2>
          <p className="text-gray-600 text-justify">
          Si tienes alguna pregunta o inquietud sobre estos Términos y Condiciones, puedes contactarnos en info@tuespacio.com o a través de nuestro sitio web www.tuespacio.com.
          </p>
        </section>
      </div>
      </div>

      <Footer />

    </div>
   
  );
};

export default Terms;
