import React, { useEffect } from 'react';  // Asegúrate de importar useEffect
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Forzar el desplazamiento al inicio cuando se carga el componente
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
    <div className="min-h-screen bg-gray-50 flex flex-col items-center p-6 md:p-12">
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-8 md:p-16">
        <h1 className="text-5xl font-bold text-gray-900 mb-4 text-center">Política de Privacidad</h1>
        <p className="text-gray-600 text-lg text-justify mb-8">
          En <span className="font-semibold">TuEspacio</span> nos tomamos muy en serio la protección de tu información personal. Esta Política de Privacidad explica cómo recopilamos, utilizamos, y protegemos tus datos personales cuando interactúas con nosotros, en cumplimiento con la Ley N° 29733, su Reglamento y Directiva.</p>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Alcance</h2>
          <p className="text-gray-600 text-justify mb-8">
          Esta política se aplica a los datos personales que recopilamos a través de nuestros medios digitales (sitio web, chatbot, aplicaciones móviles), así como de servicios prestados por vía telefónica u otros medios de atención que<span className="font-semibold">TuEspacio</span> pone a disposición de sus USUARIOS. Para fines de esta Política, el término USUARIO hace referencia a cualquier persona que haya interactuado con Tu Espacio y que nos haya proporcionado sus datos personales.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Términos y Definiciones</h2>
          <ul className="list-disc list-inside text-gray-600 ">
            <li className="mb-2"><span className="font-semibold">Datos Personales:</span> Cualquier información sobre una persona natural que permite identificarla.</li>
            <li className="mb-2"><span className="font-semibold">Datos Sensibles:</span> Información relativa a aspectos íntimos de una persona, como su salud física o mental.</li>
            <li className="mb-2"><span className="font-semibold">Banco de Datos Personales:</span> Conjunto organizado de datos personales.</li>
            <li className="mb-2"><span className="font-semibold">Tratamiento de Datos Personales:</span> Cualquier operación sobre datos personales, como la recolección, almacenamiento, uso o eliminación.</li>
            <li className="mb-2"><span className="font-semibold">Flujo Transfronterizo:</span>  Transferencia internacional de datos personales.</li>
            <li className="mb-2"><span className="font-semibold">Titular de los Datos Personales: </span> Persona a la que le corresponden los datos personales.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Consideraciones Generales</h2>
          <ul className="list-disc list-inside text-gray-600 mt-2">
          <li className="mb-2">Al registrarte en nuestros medios digitales, aceptas haber sido debidamente informado sobre el tratamiento de tus datos personales y otorgas tu consentimiento libre y expreso para dicho tratamiento.</li>
          <li className="mb-2"><span className="font-semibold">Tu Espacio</span> colabora con terceros para la prestación de sus servicios, por lo que algunos datos pueden compartirse bajo estrictos protocolos de seguridad.</li>
          <li className="mb-2">Esta Política de Privacidad no cubre sitios web de terceros a los que puedas acceder a través de nuestros enlaces.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Uso Autorizado de Datos Personales</h2>
          <p className="text-gray-600 text-justify">
          Conforme a la Ley N° 29733 y su Reglamento, <span className="font-semibold">Tu Espacio</span> está autorizado a tratar los datos personales que los USUARIOS nos proporcionan, ya sea de forma digital o física, para la prestación de nuestros servicios. Estos datos podrán incluir información relacionada con tu salud mental y emocional para poder brindarte los servicios de apoyo psicológico y atención que ofrecemos, ya sea por medio de nuestros profesionales o practicantes.
          </p>
          <p className="text-gray-600">
          Los datos personales que proporciones deberán mantenerse actualizados durante el tiempo que dure tu relación con <span className="font-semibold">Tu Espacio</span>. Algunos de estos datos serán necesarios para la correcta prestación de nuestros servicios, y tu negativa a proporcionarlos puede afectar dicha prestación.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Finalidad</h2>
          <ul className="list-disc list-inside text-gray-600 mt-2">
          <li className="mb-2">Brindar los servicios contratados.</li>
          <li className="mb-2">Evaluar la calidad de nuestros servicios.</li>
          <li className="mb-2">Mejorar nuestras plataformas digitales, incluyendo el chatbot y los servicios psicológicos.</li>
          <li className="mb-2">Realizar estudios estadísticos y de comportamiento del cliente para optimizar la experiencia del usuario.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Derechos ARCO</h2>
          <p className="text-gray-600 text-justify">
          Tienes derecho a revocar tu consentimiento en cualquier momento y a ejercer tus derechos de acceso, rectificación, cancelación y oposición (ARCO) reconocidos por la Ley N° 29733. Puedes enviar tu solicitud a través de nuestras plataformas digitales o por correo electrónico a <span className="font-semibold">info@tuespacio.com</span>.
          </p>
        </section>
      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
