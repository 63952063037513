import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import RegisterPage from '../pages/RegisterPage';
import CheckEmailPage from '../pages/CheckEmailPage';
import CheckPasswordPage from '../pages/CheckPasswordPage';
import Home from '../pages/Home';
import MessagePage from '../components/MessagePage';
import AuthLayouts from '../layout';
import Forgotpassword from '../pages/Forgotpassword';
import ProtectedRoute from '../components/ProtectedRoute';
import Inicio from '../pages/Inicio';  // Asegúrate que esté bien importado desde la carpeta correcta
import Team from '../pages/team'; // La página del equipo
import Privacidad from '../components/Legal/privacidad';
import Terminos from '../components/Legal/terminos';
import Ansiedad from '../components/Atencion/infoansiedad';
import Estres from '../components/Atencion/infoestres';
import Gestionarestres from '../components/Recursos/gestionarestres';
import Nosotros from '../components/Compañia/nosotros';
import Creadores from '../components/Compañia/creadores';

const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthLayouts><Inicio /></AuthLayouts>, // Usa el componente Inicio correctamente
    },
    {
        path: "/app",
        element: <App />, 
        children: [
            {
                path: "register",
                element: <AuthLayouts><RegisterPage /></AuthLayouts>
            },
            {
                path: 'email',
                element: <AuthLayouts><CheckEmailPage /></AuthLayouts>
            },
            {
                path: 'password',
                element: <AuthLayouts><CheckPasswordPage /></AuthLayouts>
            },
            {
                path: 'forgot-password',
                element: <AuthLayouts><Forgotpassword /></AuthLayouts>
            },
            {
                path: "home",
                element: <ProtectedRoute><Home /></ProtectedRoute>,
                children: [
                    {
                        path: ':userId',
                        element: <MessagePage />
                    }
                ]
            }
        ]
    },
    {
        path: "nosotros",  // Nueva ruta para el equipo
        element: <AuthLayouts><Nosotros /></AuthLayouts> // Página de equipo (Team.js)
     
    },

    {
        path: "/privacidad",  // Ruta para la página de privacidad
        element: <AuthLayouts><Privacidad /></AuthLayouts>
    },
    {
        path: "/terminos",  // Ruta para la página de términos y condiciones
        element: <AuthLayouts><Terminos /></AuthLayouts> // Componente de términos
    },
    {
        path: "/infoansiedad",  // Ruta para la página de términos y condiciones
        element: <AuthLayouts><Ansiedad /></AuthLayouts> // Componente de términos
    },
    {
        path: "/infoestres",  // Ruta para la página de términos y condiciones
        element: <AuthLayouts><Estres /></AuthLayouts> // Componente de términos
    },
    {
        path: "/gestionarestres",  // Ruta para la página de términos y condiciones
        element: <AuthLayouts><Gestionarestres /></AuthLayouts> // Componente de términos
    },
    {
        path: "/creadores",  // Ruta para la página de términos y condiciones
        element: <AuthLayouts><Creadores /></AuthLayouts> // Componente de términos
    }
]);

export default router;
